<template>
  <div>
    <div class="pc head-container">
      <div class="tabs" ref="tabs">
        <div
          v-for="(tab, index) in tabs"
          :key="index"
          class="tab unselect"
          :class="{ active: equalPath(tab.path) }"
          @click="clickTab(tab)">
          <SvgIcon :icon="tab.icon" :color="getColor(tab.path)" :width="20" :height="20" />
          <span>{{ tab.name }}</span>
        </div>
      </div>
    </div>
    <router-view class="router-view"></router-view>
  </div>
</template>
<script>
import SvgIcon from '../component/SvgIcon';
export default {
  name: 'MyPages',
  components: { SvgIcon },
  mounted() {
    setTimeout(() => {
      this.scrollToActiveTab();
    }, 1000);
  },
  data() {
    return {
      tabs: [
        {
          name: '내 서비스',
          path: 'my_service',
          icon: 'u_web-grid-alt',
        },
        {
          name: '문의내역',
          path: 'inquiry',
          icon: 'u_comment-alt-message',
        },
        {
          name: '미팅 관리',
          path: 'my_meeting',
          icon: 'u_calender',
        },
        {
          name: '계약내역',
          path: 'my_contract',
          icon: 'u_file-alt',
        },
        {
          name: '소스 파일 다운로드',
          path: 'my_templates',
          icon: 'u_download-alt',
        },
        {
          name: '북마크',
          path: 'my_bookmark',
          icon: 'u_bookmark',
        },
        {
          name: '내 정보 수정',
          path: 'profile',
          icon: 'u_edit',
        },
      ],
    };
  },
  watch: {
    $route: {
      handler() {
        this.scrollToActiveTab();
      },
      immediate: true,
    },
  },
  methods: {
    equalPath(path) {
      return this.$route.fullPath.replace('/mypage/', '').split('/')[0] === path;
    },
    getColor(path) {
      return this.equalPath(path) ? '#ff6600' : '#4f4f4f';
    },
    scrollToActiveTab() {
      this.$nextTick(() => {
        let activeTab = this.tabs.findIndex((tab) => this.equalPath(tab.path));
        if (activeTab !== -1) {
          const tabElement = this.$refs.tabs.children[activeTab];
          if (tabElement) {
            tabElement.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
              inline: 'center',
            });
          }
        }
      });
    },
    clickTab(item) {
      this.setGa(`마이페이지 탭 (${item.name})`, '클릭', item.name);
      this.$router.push('/mypage/' + item.path);
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/css/lp_main'

.head-container
  padding 60px 0 0 0
  width 100%
  height auto
  background-color white
  border-bottom 1px solid $gray1
  z-index 9
  display flex
  justify-content center
  align-items center

.tabs
  display flex
  justify-content center
  align-items center
  gap 24px
  .tab
    display flex
    justify-content center
    align-items center
    gap 8px
    padding 20px 0 18px 0
    border-bottom 2px solid transparent
    font-size 15px
    font-weight 500
    color #4f4f4f
  .tab.active
    border-bottom 3px solid #ff6600
    color #ff6600


.primary
  color $primary

.router-view
  background-color $gray3
  padding-bottom 120px
  min-height calc(100vh - 64px)

.icon-menu
  width 24px


@media(max-width:1024px)
  .head-container
    overflow-x auto
    display block
    overflow-x auto
    white-space nowrap
    width 100%
    justify-content flex-start !important
    .tabs
      display inline-flex
      justify-content flex-start
      align-items center
      gap 24px
      .tab
        display inline-flex
        justify-content center
        align-items center
        border-bottom 2px solid transparent
      .tab:first-child
        margin-left 16px
      .tab:last-child
        margin-right 16px
</style>
